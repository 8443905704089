import i18next from 'i18next';
import { useTranslation } from 'react-i18next';

import { TranslationNamespace } from 'i18n';

export const isValidString = (str: string, regex: RegExp, length?: number) => {
  if (length && str.replace(/\s/g, '').length !== length) return false;
  return regex.test(str.replace(/\s/g, ''));
};

export const getTCommon = () => i18next.t;

export const useTFeature = () =>
  useTranslation(TranslationNamespace.Common, {
    keyPrefix: 'features.form_builder',
  });
