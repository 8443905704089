import { createContext } from 'react';

import { Order, TradeMethod } from 'types';

export type OrderContextProps = {
  order?: Order;
  tradeMethod?: TradeMethod;
  isTSBP?: boolean;
  isSBP?: boolean;
  isTSBPSingleBankSelection?: boolean;
  isTCard2Card?: boolean;
  requisitesBankName?: string;
  customerBank?: string;
  customerBankName?: string;
};

export const OrderContext = createContext<OrderContextProps>(
  {} as OrderContextProps,
);
