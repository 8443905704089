import { Fragment, useCallback, useMemo } from 'react';

import {
  DEFAULT_PAYMENT_RULE_RENDER,
  PAYMENT_RULES,
  PaymentRuleOptions,
} from 'constants/payment-rule-constants';
import { useOrderContext } from 'hooks';

type Props = {
  requisitesCountry?: string;
  requisitesBank?: string;
};

export const PaymentDetailsRules: React.FC<Props> = ({
  requisitesCountry,
  requisitesBank,
}) => {
  const {
    isTSBP,
    isSBP,
    isTCard2Card,
    customerBankName,
    customerBank,
    tradeMethod,
  } = useOrderContext();

  const paymentRuleOptions: PaymentRuleOptions = useMemo(
    () => ({
      isTSBP,
      isSBP,
      isTCard2Card,
      customerBankName,
      customerBank,
      fiatCurrency: tradeMethod?.fiatCurrency,
      requisitesCountry,
      requisitesBank,
    }),
    [
      isTSBP,
      isSBP,
      isTCard2Card,
      customerBankName,
      customerBank,
      tradeMethod,
      requisitesCountry,
      requisitesBank,
    ],
  );

  const renderRules = useCallback(() => {
    const paymentRule = PAYMENT_RULES.find((rule) =>
      rule.isMatch(paymentRuleOptions),
    );
    if (paymentRule) {
      return paymentRule.render(paymentRuleOptions);
    }
    return DEFAULT_PAYMENT_RULE_RENDER(paymentRuleOptions);
  }, [paymentRuleOptions]);

  return <Fragment>{renderRules()}</Fragment>;
};
