export enum Bank {
  Tinkoff = 'tinkoff',
  Sberbank = 'sberbank',
  Namba = 'namba',
  VTB = 'vtb',
  Amra = 'amra-bank',
  Amobile = 'amobile',
  MBank = 'mbank',
  Kompanion = 'kompanion',
}
