import cx from 'classnames';
import { map } from 'lodash';
import React, { useMemo } from 'react';

import { LogoImage } from 'components';
import { BANKS_BY_TRADE_METHOD } from 'constants/payment-type.constants';
import { Bank } from 'enums';
import { useOrderContext } from 'hooks';
import { FormFieldProps, GetFieldValidatorFn } from 'types';

import { TextField } from './TextField';
import { useTFeature } from '../fields.utils';
import { getBaseFieldValidator } from '../form.validators';

import './Bank.styles.scss';

export const Field: React.FC<FormFieldProps> = ({
  field: { name, value },
  autoFocus,
  onChange,
}) => {
  const { t } = useTFeature();

  const { isTSBPSingleBankSelection, tradeMethod } = useOrderContext();

  const bankClasses = useMemo(
    (): any => ({
      [Bank.Tinkoff]: 'md:tw-p-1 tw-p-1',
      [Bank.Sberbank]: 'md:tw-py-4 tw-py-1 tw-px-4',
      [Bank.VTB]: 'md:tw-py-4 tw-py-1 tw-px-4',
    }),
    [],
  );

  const banks = useMemo(
    () =>
      tradeMethod &&
      BANKS_BY_TRADE_METHOD.find((item) => item.isMatch(tradeMethod))?.banks,
    [tradeMethod],
  );

  if (banks?.length) {
    return (
      <div className={cx({ 'tw-mt-6': !isTSBPSingleBankSelection })}>
        <div className="md:tw-text-xl tw-font-bold tw-text-primary tw-mb-4">
          {t('fields.bank.placeholder_select')}
        </div>
        <div className="tw-grid tw-grid-cols-2 tw-gap-4">
          {map(banks, (bank) => (
            <div
              key={bank}
              className={cx(
                'bank-logo-wrapper tw-flex tw-items-center tw-justify-center tw-cursor-pointer tw-bg-primary-input',
                'md:tw-h-16 tw-h-12 tw-p-2 tw-border-2 tw-border-component tw-rounded-xl hover:tw-bg-layout-accent-alt',
                bankClasses[bank] || 'tw-p-2',
                {
                  'hover:tw-border': value !== bank,
                  'tw-border-component-accent':
                    value && value === bank && !isTSBPSingleBankSelection,
                },
              )}
              onClick={() => onChange(value === bank ? '' : bank)}
            >
              <LogoImage
                bank={bank}
                bankName={bank}
                paymentTypeName="tsbp"
                className="tw-h-full tw-text-logo-font tw-leading-5 tw-block tw-overflow-hidden	tw-text-ellipsis"
              />
            </div>
          ))}
        </div>
      </div>
    );
  }

  return (
    <TextField
      name={name}
      value={value}
      placeholder={t('fields.bank.placeholder')}
      autoFocus={autoFocus}
      onChange={onChange}
    />
  );
};

export const getValidator: GetFieldValidatorFn = ({ required }) =>
  getBaseFieldValidator({ required });
